.centerbox {
  display: flex; 
  height: 100vh; 
  width: 100%; 
  align-items: center;
  justify-content: center;
  align-self: center;
  background: rgba(0, 0, 0, 0.5); 
  position: fixed;
  z-index: 999999;
  top: 0;
}
  
.centerbox img{
  max-width:80px; 
}